import axiosAdmin from "../../api/axiosAdmin";
import AddedUserPop from "../../components/ActionPop/AddedUserPop";
import CustomNotification from "../../components/CustomNotification";
import Cookies from 'universal-cookie';
import { expireTime } from "../../utils";
import axiosManager from "../../api/axiosManager";

const cookies = new Cookies();

const userModel = {
    state: {
        docs: [],
        page: 0,
        totalDocs: 0,
        limit: 10,
    },
    reducers: {
        setUserData(state, payload) {
            return { ...state, ...payload };
        },
    },
    effects: (dispatch) => ({
        async getAllUsers({ isManager, ...payload }) {
            await (isManager ? axiosManager : axiosAdmin).post(`/api/admin/users/get`, payload).then(res => {
                if (res.data.code === 200) {
                    this.setUserData(res.data.data);
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async addUser({ isManager, ...payload }) {
            const { formData, handleModal } = payload;
            await (isManager ? axiosManager : axiosAdmin).post('/api/admin/users', formData).then(res => {
                if (res.data.code === 200) {
                    handleModal(null, false);
                    dispatch.notiModel.setModalData({
                        Component: <AddedUserPop
                            title={`Add A System ${formData.isAdmin ? "Admin" : "User"}`}
                            subTitle={<p>
                                {formData.isAdmin?"Admin":"User"} <span>{formData.email}</span> has Added and an invitation email has been
                                sent
                            </p>}
                            className="error_modal"
                            onCancel={() => dispatch.notiModel.setModalData({})}
                        />,
                        className: "add_user_modal"
                    });
                    this.getAllUsers({ page: 1, isManager, isAdmin:formData.isAdmin });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async updateUser({ isManager, isAdmin,...payload }) {
            const { formData, handleModal, id } = payload;
            await (isManager ? axiosManager : axiosAdmin).put(`/api/admin/users/${id}`, formData).then(res => {
                if (res.data.code === 200) {
                    handleModal(null, false);
                    this.getAllUsers({ page: 1, isManager , isAdmin : formData.isAdmin});
                    dispatch.notiModel.setModalData({
                        Component: <AddedUserPop
                            title={`Edit A System ${formData.isAdmin ? "Admin" : "User"}`}
                            subTitle={<p>
                                {formData.isAdmin?"Admin":"User"} <span>{formData.email}</span> has been edited successfully
                            </p>}
                            className="error_modal"
                            onCancel={() => dispatch.notiModel.setModalData({})}
                        />,
                        className: "add_user_modal"
                    });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async deleteUser({ isManager,isAdmin, ...payload }) {
            const { id, handleInfoPop } = payload;
            await (isManager ? axiosManager : axiosAdmin).delete(`/api/admin/users/${id}`).then(res => {
                if (res.data.code === 200) {
                    handleInfoPop({});
                    this.getAllUsers({ page: 1, isManager, isAdmin });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async disableUser({ isManager,isAdmin, ...payload }) {
            const { formData, record, handleInfoPop } = payload;
            await (isManager ? axiosManager : axiosAdmin).post(`/api/admin/users/${record._id}/change-status`, formData).then(res => {
                if (res.data.code === 200) {
                    handleInfoPop({});
                    this.getAllUsers({ page: 1, isManager, isAdmin });
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async resetUserPassword({ isManager, ...payload }) {
            const { email, handleInfoPop } = payload;
            await (isManager ? axiosManager : axiosAdmin).post('/api/auth/forgot-password/sendLink', { email, type: "reset" }).then(res => {
                if (res.data.code === 200) {
                    handleInfoPop({});
                } else {
                    CustomNotification({
                        title: "Info", subTitle: res.data.message, dispatch
                    });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
        async ghostLogin({ isManager, ...payload }) {
            const { id } = payload;
            await (isManager ? axiosManager : axiosAdmin).get(`/api/admin/users/${id}/login`).then(async (res) => {
                if (res.data.code === 200) {
                    switch (res.data.data.role) {
                        case "Admin":
                            cookies.set(`x-auth-token`, res.data.data.token, { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
                            // await this.getAdminDetails();
                            // handleNavigate('/user');
                            window.open('/user', '_blank');
                            break;

                        case "Manager":
                            cookies.set(`m-auth-token`, res.data.data.token, { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
                            window.open('/manager/user', '_blank');
                            break;

                        default:
                            cookies.set(`c-auth-token`, res.data.data.token, { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
                            window.open('/client', '_blank');
                    }
                    // cookies.set(`c-auth-token`, res.data.data.token, { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
                    // window.open('/client', '_blank');
                } else {
                    CustomNotification({ title: "Info", subTitle: res.data.message, dispatch });
                }
            }).catch(err => {
                CustomNotification({ title: "Error", subTitle: err.message, dispatch });
            })
        },
    }),
};

export default userModel;