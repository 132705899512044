import React from 'react';
import { ReactComponent as Traingle } from '../../assets/image/triangle.svg';
import { Link } from 'react-router-dom';
import { normalizeUrl } from '../../utils';

function Sidebar({ navList, handleNavigate, active, handleSubNav }) {
  function checkActivePathname(menu, pathname) {
    // Ensure activeName is an array before calling .some
    const activeNames = Array.isArray(menu?.activeName) ? menu?.activeName : [];

    return activeNames.length > 0
      ? activeNames.some(activePath => {
        if (activePath.includes('id')) {
          return pathname.includes(activePath.split('/id')[0]);
        }
        return false;
      })
      : false;
  }
  return (
    <div className='sidebar_panel'>
      <ul className='user_panel'>
        {navList.map((nav, index) => {
          if (nav.subMenu.length > 0) {
            let check = (nav.subMenu.filter(data => {
              return String(active.path).toLowerCase().split('/').includes(String(data.activeName).toLowerCase())
            }).length > 0);
            return (
              <>
                <li
                  className={`${check ? 'active lms' : ''}`}
                  onClick={() => handleSubNav(nav, index)}
                >
                  <a to=''>{nav.title}</a>
                  <Traingle
                    className={`${check && active.toggle
                      ? 'traingle'
                      : 'traingle active'
                      }`}
                  />
                </li>
                {check && active.toggle && (

                  <ul className='sub_menu'>
                    {
                      nav.subMenu.map(sub => {
                        return (
                          <li
                            onClick={() => handleNavigate(sub.path)}
                            className={`${String(window.location.pathname).toLowerCase().split('/').includes(String(sub?.activeName).toLowerCase()) ? "active" : ""}`}
                          >
                            <a to=''>{sub.title}</a>
                          </li>
                        )
                      })
                    }
                  </ul>
                )}
              </>
            );
          } else {
            const check = (!!nav?.activeName ? checkActivePathname(nav, window.location.pathname) : (nav.path === window.location.pathname))
            console.log(check, "check", "pathname: ", window.location.pathname, "nav.path: ", nav.path, "nav.activeName: ", nav.activeName);
            return (
              <li
                onClick={() => handleNavigate(nav.path)}
                key={nav.id}
                className={`${check ? 'active' : ''} `}
              >
                <Link to=''>{nav.title}</Link>
              </li>
            );
          }
        })}
        {/* <li className=""><Link to="">user</Link></li> */}
        {/* <li className="active lms"><Link to="">LMS</Link>
          <ul className='sub_menu'>
            <li><Link to="">Clients</Link></li>
            <li><Link to="">JOBS</Link></li>
            <li><Link to="">NCOA</Link></li>
          </ul>
        </li> */}
      </ul>

      {/* <Menu
        selectedKeys={[active]}
        mode='inline'
        theme='dark'
        inlineCollapsed={false}
        items={navList}
        onClick={(e) => handleClick(e)}
        expandIcon={handleToggle}
      /> */}
    </div>
  );
}

export default Sidebar;