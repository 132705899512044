import axios from "axios";
import Cookies from 'universal-cookie';
import { expireTime } from "../utils";

const cookies = new Cookies();

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_END_POINT,
});

axiosClient.defaults.timeout = 600000;

axiosClient.interceptors.response.use(
    function (response) {
        if (response.data?.code === 401) {
            cookies.remove('c-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
            window.location.reload();
        }
        return response;
    },
    function (error) {
        let res = error.response;
        if (res?.code === 401) {
            cookies.remove('c-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
            window.location.reload();
        }
        console.error("Looks like there was a problem. Status Code:" + res?.status);
        return Promise.reject(error);
    }
);

axiosClient.interceptors.request.use(function (config) {
    const token = cookies.get('c-auth-token');
    config.headers.authorization = token || '';
    config.headers.adminAuthorization = 'client';

    return config;
});

export default axiosClient;