
export const userNav = [
  {
    id: 1,
    title: "Admin",
    path: "/admin",
    subMenu: [],
  },
  {
    id: 2,
    title: "USERS",
    path: "/user",
    subMenu: [],
  },
  {
    id: 3,
    title: "Clients",
    path: "/client-list",
    activeName: ["/client-list", "/client-list/id"],
    subMenu: [],
  },
  {
    id: 4,
    title: "VIN DECODER",
    activeName: "vin-decoder",
    path: "/vin-decoder",
    subMenu: [],
  },
  {
    id: 5,
    title: "Dms Stats",
    path: "/dms-stats",
    subMenu: [],
  },
  {
    id: 6,
    title: "File Deletion",
    path: "/file-deletion",
    subMenu: [],
  },
  {
    id: 7,
    title: "Export",
    path: "/export-jobdrop-data",
    subMenu: [],
  },
];
export const ManagerNav = [
  {
    id: 1,
    title: "USERS",
    path: "/manager/user",
    subMenu: [],
  },
  {
    id: 2,
    title: "Clients",
    path: "/manager/client-list",
    activeName: ["/manager/client-list", "/manager/client-list/id"],
    subMenu: [],
  },
  {
    id: 3,
    title: "File Deletion",
    path: "/manager/file-deletion",
    subMenu: [],
  },
];
export const clientNav = [
  {
    id: 1,
    title: "LMS",
    path: "/client",
    subMenu: [
      {
        id: 1,
        title: "Clients",
        path: "/client",
        activeName: "client",
      },
      {
        id: 4,
        title: "Dms Stats",
        path: "/dms-stats/user",
        activeName: "dms-stats",
      },
      {
        id: 2,
        title: "JOBS-DROPS",
        activeName: "job-drops",
        path: "/job-drops",
      },
      {
        id: 3,
        title: "NCOA",
        activeName: "ncoa-list",
        path: "/ncoa-list",
      },
    ],
  },
  {
    id: 2,
    title: "MI4",
    path: "/page1",
    subMenu: [
      {
        id: 1,
        title: "Page 1",
        path: "/page1",
        activeName: "page1",
      },
      {
        id: 2,
        title: "Page 2",
        path: "/page2",
        activeName: "page2",
      },
    ],
  },
];

