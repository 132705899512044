import React from "react";
import { Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import Loader from "../../Layout/Loader";
import { isAuthenticated } from "../../utils";
import { PrivateRouteList } from "../data/routes";
import AdminLayout from "../../Layout/AdminLayout";
import CustomLayout from "../../Layout/CustomLayout";

const privateRouter = (handleNavigate, client, role) => {

    const PrivateRouters = ({ children }) => {
        let token = isAuthenticated();
        if (!token) {
            return <Navigate to="/" />;
        }
        return children;
    };


    return (
        PrivateRouteList.map(({ component: Component, path, name, pageType, layout }, key) => {
            switch (layout) {
                case "custom": {
                    return (
                        <Route
                            path={path}
                            element={
                                <PrivateRouters>
                                    <CustomLayout handleNavigate={handleNavigate}>
                                        <React.Suspense fallback={<Loader />}>
                                            <Component handleNavigate={handleNavigate} client={client} role={role} pageType={pageType}
                                            />
                                        </React.Suspense>
                                    </CustomLayout>
                                </PrivateRouters>
                            }
                            key={key}
                        />
                    );
                }
                default: {
                    return (
                        <Route
                            path={path}
                            element={
                                <PrivateRouters>
                                    <AdminLayout handleNavigate={handleNavigate} title={name}>
                                        <React.Suspense fallback={<Loader />}>
                                            <Component handleNavigate={handleNavigate} client={client} role={role} pageType={pageType}
                                            />
                                        </React.Suspense>
                                    </AdminLayout>
                                </PrivateRouters>
                            }
                            key={key}
                        />
                    )
                }

            }
        })
    )
};

export default privateRouter