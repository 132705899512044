import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Routes, useNavigate, Route, useLocation } from 'react-router-dom';
import privateRouter from './routes/PrivateRouter';
import publicRouter from './routes/PublicRouter';
import clientRouter from './routes/ClientRouter';
import store from './store';
import NotFound from './Layout/NotFound';
import { useSelector } from 'react-redux';
import managerRouter from './routes/ManagerRouter';
import { axiosInstance } from './utils';

const cookies = new Cookies();

function App() {
  const client = useSelector(state => state.authModel.client);
  const manager = useSelector(state => state.authModel.manager);
  const admin = useSelector(state => state.authModel.admin);


  const { dispatch } = store;
  const navigate = useNavigate();
  const location = useLocation(); // Listen for route changes
  const handleNavigate = (link) => {
    navigate(link);
  };

  useEffect(() => {
    const adminToken = cookies.get('x-auth-token');
    const clientToken = cookies.get('c-auth-token');
    const managerToken = cookies.get('m-auth-token');

    if (managerToken) {
      dispatch.authModel.getManagerDetails();
    }

    if (adminToken) {
      dispatch.authModel.getAdminDetails();
    }

    if (clientToken) {
      dispatch.authModel.getClientDetails();
    }
  }, []);

  const setTableHeight = () => {
    let tableHeight = '';

    if (location.pathname.includes('file-deletion')) {
      tableHeight = window.innerHeight - (116 + 20 + document.querySelector('.file_deletion_User_Panel')?.offsetHeight + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else if (location.pathname.includes('jobs')) {
      tableHeight = window.innerHeight - (116 + 20 + 66 + document.querySelector('.client_sub_panel')?.offsetHeight + document.querySelector('.date_range_panel')?.offsetHeight + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else if (location.pathname.includes('clean-files')) {
      tableHeight = window.innerHeight - (116 + 20 + 66 + document.querySelector('.client_sub_panel')?.offsetHeight + document.querySelector('.date_range_panel')?.offsetHeight + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else if (location.pathname.includes('ncoa')) {
      tableHeight = window.innerHeight - (110 + 20 + 66 + document.querySelector('.client_sub_panel')?.offsetHeight + document.querySelector('.date_range_panel')?.offsetHeight + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else if (location.pathname.includes('client')) {
      tableHeight = window.innerHeight - (116 + 20 + 77 + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else {
      tableHeight = window.innerHeight - (116 + 20 + 77 + document.querySelector('.ant-table-cell')?.offsetHeight);
    }

    document.documentElement.style.setProperty('--table-body-height', `${tableHeight}px`);
  };
  

  useEffect(() => {
    const updateTableHeight = () => {
      setTimeout(() => {
        requestAnimationFrame(() => {
          setTableHeight();
        });
      }, 2000); // Delays execution by 2 seconds
    };

    updateTableHeight(); // Run on mount & route change

    // Monitor DOM changes to adjust height when elements load
    const observer = new MutationObserver(updateTableHeight);
    observer.observe(document.body, { childList: true, subtree: true });

    // Window resize event
    window.addEventListener('resize', updateTableHeight);

    return () => {
      observer.disconnect(); // Cleanup observer
      window.removeEventListener('resize', updateTableHeight);
    };
  }, [location]);

  // Function to open a route in a new tab


  return (
    <>
      <Routes>
        {publicRouter(handleNavigate)}
        {privateRouter(handleNavigate, admin, "Admin")}
        {clientRouter(handleNavigate, client, "Client")}
        {managerRouter(handleNavigate, manager, "Manager")}
        <Route path="*" element={<NotFound handleNavigate={handleNavigate} />} />
      </Routes>
    </>
  );
}

export default App;
