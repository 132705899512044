import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import CustomModal from '../../components/CustomModal';
import store from '../../store';
import Footer from '../Footer';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { expireTime, isAuthenticated, isClientAuthenticated, isManagerAuthenticated } from '../../utils';
import { useSelector } from 'react-redux';
import { userNav } from '../../routes/data/menulist';
import { ManagerNav } from '../../routes/data/menulist';

const cookies = new Cookies();

const ManagerLayout = ({ children, handleNavigate, ...rest }) => {
  const [togglePwd, setTogglePwd] = useState(false);
  const [navList, setNavList] = useState(ManagerNav);
  const modal = useSelector((state) => state.notiModel);
  const manager = useSelector((state) => state.authModel.manager);
  const { visible, Component, className } = modal;
  const { dispatch } = store;


  const logOut = () => {
    cookies.remove('m-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
    cookies.remove('c-auth-token', { path: '/', expires: expireTime(), secure: true, sameSite: 'strict' });
    handleNavigate('/', { replace: true });
    localStorage.clear();
    if (isAuthenticated()) {
      window.close();
    }
  };

  const changePasswordPop = () => {
    setTogglePwd(true);
  };

  const changePassword = (e) => {
    let formData = { ...e };
    delete formData.confirmPassword;
    dispatch.authModel.changePassword({ formData, onCancel: handlePwdPop, admin: true });
  };

  const handlePwdPop = () => {
    setTogglePwd(!togglePwd);
  }
  useEffect(() => {
    dispatch.authModel.setUserDetails({ name: 'userRole', data: 'Manager' });
  }, [manager]);
  return (
    <>
      <div>
        <Header
          handleNavigate={handleNavigate}
          logOut={logOut}
          changePasswordPop={changePasswordPop}
          togglePwd={togglePwd}
          user={manager}
          handlePwdPop={handlePwdPop}
          changePassword={changePassword}
        />
        <div className={`${isManagerAuthenticated() || isClientAuthenticated() || isAuthenticated() ? 'dashboard_main' : ''}`}>
          <div className={`${isManagerAuthenticated() || isClientAuthenticated() || isAuthenticated() ? 'dashboard_content' : ''}`}>
            {(isManagerAuthenticated() || isClientAuthenticated() || isAuthenticated()) && (
              <Sidebar
                navList={navList}
                handleNavigate={handleNavigate}
              />
            )}
            {children}
          </div>
        </div>

        <Footer />
      </div>
      <CustomModal
        className={className}
        visible={visible}
        onCancel={() => dispatch.notiModel.setModalData()}
      >
        {Component}
      </CustomModal>
    </>
  );
};

export default ManagerLayout;
